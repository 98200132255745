<template>
  <div class="flex flex-col min-h-screen">
    <Header />

    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md md:text-xl">
        <template v-if="loading">
          <Loading />
        </template>

        <template v-else>
          <div class="relative">
            <div v-for="(image, index) in images" :key="index">
              <img
                :src="image.name"
                :alt="image.slug"
                :class="`w-full ${
                  index == 0
                    ? 'rounded-t-lg'
                    : index == images.length - 1
                    ? 'rounded-b-lg'
                    : ''
                }`"
              />

              <div
                :class="`flex w-full justify-center absolute ${image.tag}`"
                v-if="image.tag"
              >
                <button
                  class="w-full h-48 sm:h-96  shadow-md hover:shadow-lg"
                  type="button"
                  @click="generateVideo('modal-example-large', image.tag)"
                >
                </button>
                <!-- <div :id="image.tag" class="w-full h-48 sm:h-96"></div> -->
                <!-- <cld-video
                  cloudName="dme8vuklt"
                  publicId="samples/sea-turtle"
                  secure="true"
                  controls
                >
                  <cld-transformation
                    :overlay="{
                      fontFamily: 'arial',
                      fontSize: 60,
                      text: 'Cool%2012',
                    }"
                    gravity="south"
                    y="80"
                    startOffset="0"
                    endOffset="13"
                  />
                </cld-video> -->
              </div>
            </div>

            <!-- modal -->
            <div
              class="hidden overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
              id="modal-example-large">
              <div class="relative sm:h-full w-full my-6 mx-auto max-w-6xl">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col sm:h-full w-full bg-white outline-none focus:outline-none">
                  <!--header-->
                  <div class="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t">
                    <button
                      class="p-1 ml-auto bg-transparent border-0 text-gray-300 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      @click="toggleModal('modal-example-large')">
                      <span class="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                        X
                      </span>
                    </button>
                  </div>
                  <!--body-->
                  <div class="relative p-6 flex-auto w-full h-52 sm:h-full" id="watermark-video">
                    <!-- video here -->
                  </div>
                  <!--footer-->
                  <div class="flex items-center justify-end p-6 border-t border-solid border-gray-200 rounded-b">
                    <button
                      class="text-purple-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button" @click="toggleModal('modal-example-large')">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden opacity-25 fixed inset-0 z-40 bg-black" id="modal-example-large-backdrop"></div>
            
            <div
            v-show="participant.participant.status !== 'passed'"
              @click="doPostTest"
              :class="`absolute flex bottom-1 justify-center bottom-1 w-full cursor-pointer ${
                loadingCursor ? 'cursor-wait' : ''
              }`"
            >
              <img src="img/final-result/final-test.png" />
            </div>
          </div>
        </template>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Loading from "@/components/widgets/Loading.vue";
import { mapGetters, mapActions } from "vuex";
import assetApi from "@/api/asset";

export default {
  data() {
    return {
      counts: [],
      // images: [],
      videos: [],
      sourceImage: "",
      sourceVideos: "",
      loading: false,
      loadingCursor: false,
      videosIndex: 0
    };
  },

  components: {
    Loading,
  },

  created() {
    if (this.participant.participant.current_step == "post-test") {
      this.loading = false;
      this.logout();
      this.$router.push({ name: "Login" });
      this.$swal("Oops...", "Mohon tidak menggunakan tombol kembali pada browser atau device anda", "error");
    } else {
      this.updateCurrentStepOnlyState({
        id: this.participant.participant.id,
        current_step: "guidelines",
      })
    }
  },

  async mounted() {
    await this.getImages();
    //await this.getVideos();

    // (function(v,i,d,e,o){v[o]=v[o]||{}; v[o].add = v[o].add || function V(a){ (v[o].d=v[o].d||[]).push(a);};
    // if(!v[o].l) { v[o].l=1*new Date(); a=i.createElement(d); m=i.getElementsByTagName(d)[0];
    // a.async=1; a.src=e; m.parentNode.insertBefore(a,m);}
    // })(window,document,"script","https://player.vdocipher.com/playerAssets/1.x/vdo.js","vdo");
    // vdo.add({
    //   otp: "20160313versASE323iGToke8O7aPJ504UjvBUTPqIl5iq4HJPBSospgdVxsUApp",
    //   playbackInfo: "eyJ2aWRlb0lkIjoiOTU5NDZjZDQzYmE1ZDA2ODg2M2NiMmY5YTIyMTVhODgifQ==",
    //   theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
    //   container: document.querySelector( "#vdo4UjvBUTPqI" ),
    // });

    console.log(this.participant);
  },

  computed: {
    ...mapGetters(["token", "participant", "images"]),
  },

  methods: {
    ...mapActions(["updateCurrentStepOnlyState", "updateCurrentStep", "getImageWatermark", "logout"]),

    async doPostTest() {
      this.loadingCursor = true;
      try {
        const STEP = "post-test";
        await this.updateCurrentStep({
          id: this.participant.participant.id,
          current_step: STEP,
        });

        this.loadingCursor = false;
        this.$router.push({
          name: "Test",
          params: {
            typeTest: STEP,
          },
        });
      } catch (err) {
        this.loadingCursor = false;
        console.log(err);
        this.$swal("Oops", "terjadi kesalahan, silahkan coba lagi", "error");
      }
    },

    toggleModal(modalID) {
      document.getElementById("watermark-video").firstChild.remove();
      document.getElementById(modalID).classList.toggle("hidden");
      document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID + "-backdrop").classList.toggle("flex");
    },

    async generateVideo(modalID, tag) {
      document.getElementById(modalID).classList.toggle("hidden");
      document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID + "-backdrop").classList.toggle("flex");

      try {
        const res = await assetApi.getVideoByImageTag(tag);
        this.video = res.data.data;

        console.log(this.video);

        var a = '';
        var m = '';
        (function(v,i,d,e,o){v[o]=v[o]||{}; v[o].add = v[o].add || function V(a){
        (v[o].d=v[o].d||[]).push(a);};if(!v[o].l) { v[o].l=1*new Date();
        a=i.createElement(d); m=i.getElementsByTagName(d)[0]; a.async=1; a.src=e;
        m.parentNode.insertBefore(a,m);}})(window,document,"script",
        "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js","vdo");
        vdo.add({
          otp: this.video.otp,
          playbackInfo: this.video.playbackInfo,
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          container: document.querySelector("#watermark-video"),
        });

      } catch (error) {
        console.log(error);
      }

      //console.log(tag);
      // this.videosIndex = 0;
      // for (var z = 0; z < this.images.length; z++) {
      //   if (this.images[z].tag) {
      //     if (this.images[z].tag == tag) {
      //       //console.log(this.images[z].tag);
      //       var a = '';
      //       var m = '';
      //       (function(v,i,d,e,o){v[o]=v[o]||{}; v[o].add = v[o].add || function V(a){
      //       (v[o].d=v[o].d||[]).push(a);};if(!v[o].l) { v[o].l=1*new Date();
      //       a=i.createElement(d); m=i.getElementsByTagName(d)[0]; a.async=1; a.src=e;
      //       m.parentNode.insertBefore(a,m);}})(window,document,"script",
      //       "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js","vdo");
      //       vdo.add({
      //         otp: this.videos[this.videosIndex].otp,
      //         playbackInfo: this.videos[this.videosIndex].playbackInfo,
      //         theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      //         container: document.querySelector("#watermark-video"),
      //       });
      //       //console.log(this.videos[this.videosIndex].playbackInfo)
      //       break;
      //     }
      //     this.videosIndex++;
      //   }
      // }
    },

    async getImages() {
      try {
        this.loading = true;
        await this.getImageWatermark();
        // const data = res.data.data;
        // this.images = await data.map((item) => {
        //   item.name = `data:image/png;base64,${item.name}`;
        //   return item;
        // });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    // async getVideos() {
    //   try {
    //     const res = await assetApi.getVideos();
    //     this.videos = res.data.data;

    //     console.log(this.videos);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>


<style lang="css">
.bottom-1 {
  bottom: 1%;
}
</style>
