<template>
  <div class="flex flex-col min-h-screen">
    <Header />

    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md md:text-xl">
        <template v-if="loading">
          <Loading />
        </template>

        <template v-else>
          <img :src="sourceImage" class="w-full rounded-t-lg" />
          <button
            @click="doPreTest"
            class="bg-yellow-500 font-semibold w-full rounded-b-lg px-1 py-2 hover:bg-yellow-600"
          >
            Mulai
          </button>
        </template>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Loading from '@/components/widgets/Loading.vue'
import { mapActions, mapGetters } from "vuex";
import assetApi from "@/api/asset";

export default {
  data() {
    return {
      counts: [],
      sourceImage: "",
      sourceVideos: "",
      loading: true,
    };
  },

  components: {
    Loading
  },

  async mounted() {
    await this.getSourceImage();
    console.log(this.participant);
  },

  computed: {
    ...mapGetters(["participant", "token"]),
  },

  methods: {
    ...mapActions(["disclaimerDoesntAgree", "updateCurrentStep"]),

    getSourceImage() {
      assetApi
        .generateImageBySlug("img-welcome", this.token)
        .then((res) => {
          const data = res.data.data;
          this.sourceImage = `data:image/jpg;base64,${data}`;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doPreTest() {
      this.$swal({
        title: "PERHATIAN!",
        html: `
        <div class="text-sm md:text-lg overflow-y-scroll h-96">
          <div class="text-center md:text-xl">
            FORMAT PERNYATAAN 
            <br/>
            UNTUK PESERTA <i>E-LEARNING PART TIMER</i>
            <br/>
            INDOMARET GROUP
          </div>

        <div class="flex pt-2">
          <div class="w-16 text-left">Nama</div>
          <span class="pr-1">:</span>${this.participant.full_name}
        </div>
        <div class="flex pb-2">
          <div class="w-16 text-left">No HP</div>
          <span class="pr-1">:</span>${this.participant.phone_number}
        </div>
        
        <p class="text-justify">
          Sehubungan dengan program <i>e-learning</i> yang akan saya ikuti saat ini, saya secara tegas menyatakan hal-hal sebagai berikut
        </p>
        <hr class="mt-2 -mb-2">
        <ul class="text-justify list-decimal p-4 md:p-6">
          <li>
            Data profil user yang terdaftar pada sistem <i>e-learning</i> di sini adalah benar data saya sebagai calon part timer Indomaret saat mengikuti program <i>e-learning</i> ini.
          </li>
          <li>
            Saya menyatakan bersedia untuk mengikuti seluruh rangkaian program <i>e-learning</i> dari awal hingga akhir sesuai jadwal yang telah ditetapkan.
          </li>
          <li>
            Saya bersedia untuk didiskualifikasi atau dikeluarkan dari rekrutmen part timer Indomaret apabila saya tidak mengikuti seluruh rangkaian program <i>e-learning</i> ini.
          </li>
          <li>
            Saya mengerti dan memahami bahwa semua konten pembelajaran <i>e-learning</i> ini adalah milik perusahaan Indomaret dan saya akan bertanggung jawab ikut menjaga kerahasiaan dan keamanan informasi yang terkandung di dalamnya.
          </li>
          <li>
            Saya bersedia menerima sanksi sesuai peraturan yang berlaku apabila saya terbukti membocorkan atau menyebarluaskan materi program <i>e-learning</i> ini kesiapapun tanpa izin dari pihak Indomaret.
          </li>
          <li>
            Saya bersedia membayar kerugian yang timbul akibat penggunaan materi program <i>e-learning</i> di luar kepentingan perusahaan Indomaret.
          </li>
        </ul>
        <hr class="-mt-2 mb-2">
        <b>Saya menerima pernyataan ini</b>
      </div>`,
        text: "haloo",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          // TODO: Update current_step to 'pre-test' before go to PreTest component
          this.updateCurrentStep({
            id: this.participant.participant.id,
            current_step: "pre-test",
          })
            .then(() => {
              this.$router.push({
                name: "Test",
                params: {
                  typeTest: "pre-test",
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.$swal(
                "Oops",
                "terjadi kesalahan, silahkan coba lagi",
                "error"
              );
            });
        } else {
          this.disclaimerDoesntAgree(this.participant.participant.id)
            .then(() => {
              this.$router.push({ name: "Login" });
            })
            .catch((err) => {
              console.log(err);
              this.$swal(
                "Oops",
                "terjadi kesalahan, silahkan coba lagi",
                "error"
              );
            });
        }
      });
      // return this.$router.push({path : '/Test/preTest'})
    },
  },
};
</script>
