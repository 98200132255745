<template>
  <div class="flex flex-col min-h-screen">
    <Header />

    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md md:text-xl">
        <template v-if="loading">
          <Loading />
        </template>

        <template v-else>
          <div class="flex justify-center">
            <img :src="header" class="w-full rounded-t-lg" />
          </div>

          <div class="flex justify-center">
            <div class="margin-20px text-left width-657px">
              <div
                class="font-color-congrats"
                v-if="resultTest.status === 'passed'"
              >
                <b>SELAMAT !!!</b>
              </div>
              <div class="mt-3.5">
                <b>{{ resultTest.name }}</b>
              </div>
              <div>
                <p v-html="announcementMsg"></p>
              </div>

              <div
                v-show="resultTest.status !== 'failed'"
                @click="toMaterial"
                :class="`flex justify-center cursor-pointer my-4 ${
                  loadingCursor ? 'cursor-wait' : ''
                }`"
              >
                <img src="img/final-result/materi.png" />
              </div>

              <div class="mt-4">
                <span>
                  <b>HRD Indomaret</b>
                  <br />
                  <b>Semoga sukses</b>
                </span>
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <img
              src="img/final-result/final-result-footer.jpg"
              class="w-full rounded-b-lg"
            />
          </div>
        </template>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Loading from "@/components/widgets/Loading.vue";
import testApi from "@/api/test";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      resultTest: {},
      maxChance: 3,
      loading: false,
      announcementMsg: "",
      loadingCursor: false,
      header: "",
    };
  },

  components: {
    Loading,
  },

  async created() {
    if (this.participant.participant.current_step == "guidelines") {
      this.loading = false;
      this.logout();
      this.$router.push({ name: "Login" });
      this.$swal("Oops...", "Mohon tidak menggunakan tombol kembali pada browser atau device anda", "error");
    } else {
      this.updateCurrentStepOnlyState({
        id: this.participant.participant.id,
        current_step: "result",
      })
      await this.getResult();
    }
  },

  computed: {
    ...mapGetters(["token", "participant"]),

    convertToFloat(val) {
      let minPostTestScore = val.resultTest.min_post_test_score;
      return minPostTestScore.toFixed(2);
    },
  },

  methods: {
    ...mapActions(["updateCurrentStepOnlyState", "updateCurrentStep", 'updateStatus','logout']),

    toMaterial() {
      this.loadingCursor = true;
      this.updateCurrentStep({
        id: this.participant.participant.id,
        current_step: "guidelines",
      })
        .then(() => {
          this.loadingCursor = false;
          this.$router.push({ name: "Guidelines" });
        })
        .catch((err) => {
          this.loadingCursor = false;
          console.log(err);
          this.$swal("Oops", "terjadi kesalahan, silahkan coba lagi", "error");
        });
    },
    async getResult() {
      this.loading = true;
      try {
        let res = await testApi.getResult(this.token);
        const data = res.data.data;

        this.resultTest = {
          name: data.full_name,
          status: data.participant.status,
          post_test_score: data.participant.post_test_score,
          min_post_test_score: data.participant.min_post_test_score,
          chance: this.maxChance - data.participant.post_test_total_attempt,
        };

        this.mappingDataByStatus(this.resultTest);

        this.updateStatus(data.participant.status);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    mappingDataByStatus(result) {
      if (result.status === "passed") {
        this.header = "img/final-result/42-header.jpg";
        this.announcementMsg = `Hasil Tes e-learning kamu adalah <b>${result.post_test_score}</b> dan artinya memenuhi syarat nilai minimal lulus(${this.convertToFloat}). Selamat kamu lolos tahap selanjutnya yaitu penilaian di Toko.`;
      } else if (result.status === "remedial") {
        this.header = "img/final-result/43-header.jpg";
        this.announcementMsg = `Hasil Tes e-learning kamu adalah <b>${result.post_test_score}</b> dan artinya tidak memenuhi syarat nilai minimal lulus(${this.convertToFloat}). Kamu masih punya kesempatan ${result.chance} kali untuk mengulang`;
      } else if (result.status === "failed") {
        this.header = "img/final-result/44-header.jpg";
        this.announcementMsg = `Hasil Tes e-learning kamu adalah <b>${result.post_test_score}</b> dan artinya tidak memenuhi syarat nilai minimal lulus(${this.convertToFloat}). <b>Dan kamu sudah tidak punya kesempatan untuk mengulang.</b>`;
      }
    },
  },
};
</script>
